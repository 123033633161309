import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;
    const description = this.props.data.description;

    return (
      <header id="home">
        <ParticlesBg type="cobweb" num="150" bg={true}/>

        <nav id="nav-wrap">
                <a className="mobile-btn" href="/ai-course#nav-wrap" title="Show navigation">
            Show navigation
          </a>
                <a className="mobile-btn" href="/ai-course#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#resume">
                Agenda
              </a>
            </li>
            
            <li>
              <a className="smoothscroll" href="#about">
                About the author
              </a>
            </li>

            <li>
               <a className="sub-button" href='https://72dd8f72.sibforms.com/serve/MUIEADdH5F6mykDcNRJmzzp08XafQ3J6jyPJA_nywysmmsVIn8gEMRIFD1znYcHbQxg8BG12ehFYtKiRyEPwGQ6-fh7N9GKmUYY-ofyBAjhoJgseszHyuEhQ1w8LgLdiIiT2AQtxw2zKbFhJC0ZYvy0Rl66raiDblr74hc90w1uRJhR_JU5vS8jWDkMQyRP-LfwExr9hdEn50qyx'>SUBSCRIBE</a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline">Learn <span style={{color: "#000524"}}>AI</span> in Azure<span style={{color: "#000524"}}>.</span></h1>
              <h1 className="responsive-headline">By email<span style={{color: "#000524"}}>.</span></h1>
              <h1 className="responsive-headline">For free<span style={{color: "#000524"}}>.</span></h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}</h3>
            </Fade>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
