import React, { Component } from "react";
import Fade from "react-reveal";

class Portfolio extends Component {
  render() {

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
            <div className="my-action-button">
                    <h2>Let's start learning AI in Azure!</h2>
                    <button onClick={() => { window.location.href = 'https://72dd8f72.sibforms.com/serve/MUIEADIaNazWIYZra1ahOEvUu24Ylma7lmdEHMsczFR7z5IFcBQVx3YdVN-pX6QT8O0TjAT4NxBxEXB6UVhE9thmDcPVmLvBh4wKxIBvh_pdErz75hTSwdbHKJkFgKs1ZDD6gqkXFLpKPMrKDsHYNio_968X8k-DqYbekW2b4nIYyqZ5MahUPpvYnibg0RDUcp8BT_r5rI5_jqGs'} }>Subscribe</button>
            </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
