import React, { Component } from "react";
import Fade from "react-reveal";

class Header extends Component {
    render() {
        return (
            <div className="lifetime">
                <Fade bottom>
                    <img src="images/lifetime/header.svg" />
                    <h1>Let's see how is your <span style={{ color: "#cb31cd" }}>lifetime</span>.</h1>
                </Fade>
            </div>
        );
    }
}

export default Header;
