import React, { Component } from "react";

class Home extends Component {

    render() {

        const social = [
            {
                "name": "twitter",
                "url": "https://twitter.com/KamilBugnoKrk",
                "className": "fa fa-twitter"
            },
            {
                "name": "linkedin",
                "url": "https://www.linkedin.com/in/kamil-bugno/",
                "className": "fa fa-linkedin"
            },
            {
                "name": "github",
                "url": "https://github.com/KamilBugnoKrk",
                "className": "fa fa-github"
            },
            {
                "name": "instagram",
                "url": "https://www.instagram.com/kamilbugnokrk/",
                "className": "fa fa-instagram"
            }];

        const networks = social.map(function (network) {
            return (
                <li key={network.name}>
                    <a href={network.url}>
                        <i className={network.className} />
                    </a>
                </li>
            );
        });

    return (
        <div id="main-home">
            <h1>Hi, I am <span style={{ color: "#036666" }}>Kamil Bugno</span>.</h1>
            <h3>I am a web developer focused on <span style={{ color: "#036666" }}>C#</span>, <span style={{ color: "#036666" }}>.NET</span>, <span style={{ color: "#036666" }}>React</span> and <span style={{ color: "#036666" }}>Azure</span>.</h3>
            <h3>I built <a style={{ color: "#036666" }} href="https://wordtester.org/">WordTester</a> and I created a free course about <a style={{ color: "#036666" }} href="/ai-course">AI in Azure</a>.</h3>
            <h3>I share my knowledge <a style={{ color: "#036666" }} href="https://blog.kamilbugno.com">on my blog</a>.</h3>
            <div className="social-links"><>{networks}</></div>
        </div>
    );
  }
}

export default Home;
