import React, { Component } from "react";
import Fade from "react-reveal";

class Details extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="details">
        <Fade duration={1000}>
          <div className="details">
            <div className="container">
            <p style={{fontSize: '24px', margin: '-22px auto', padding: '0 5px 0 5px', background: '#000524', width: 'max-content'}}>Why choose this course?</p>
              <div className="elements">
                <div className="element">
                  <img className="icon" src="images/icons/book.png"></img>
                  <p>You will receive the most important information about using AI in Azure.</p>
                </div>   
                <div className="element">
                  <img className="icon" src="images/icons/process.png"></img>
                  <p>You will learn step by step from basics to more advanced topics.</p>
                </div>   
                <div className="element">
                  <img className="icon" src="images/icons/free.png"></img>
                  <p>You don't have to pay anything - the entire course is completely free.</p>
                </div>  
                <div className="element">
                  <img className="icon" src="images/icons/inbox.png"></img>
                  <p>You can learn in a comfortable way - all the necessary knowledge will be sent to your inbox.</p>
                </div>  
                <div className="element">
                    <img className="icon" src="images/icons/clock.png"></img>
                    <p>You will receive an email every two days. Should you not have time to read it one day, you can still do it later.</p>
                </div>
                <div className="element">
                    <img className="icon" src="images/icons/ai.png"></img>
                    <p>Thanks to this course you will know how to use state of the art AI functionality in Azure.</p>
                </div>               
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Details;
