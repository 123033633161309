import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import Home from './components/Home';
import AICourse from './components/AICourse';
import Lifetime from './components/Lifetime';

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/ai-course' component={AICourse} />
            {/*<Route path='/lifetime' component={Lifetime} />*/}
            <Route path="*" component={Home} />
          </Switch>
    );
  }
}
