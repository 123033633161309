import React, { Component } from "react";
import Header from './learnAiInAzure/Header';
import Details from './learnAiInAzure/Details';
import Resume from './learnAiInAzure/Resume';
import About from './learnAiInAzure/About';
import Portfolio from './learnAiInAzure/Portfolio';
import Footer from './learnAiInAzure/Footer';
import $ from "jquery";

class AICourse extends Component {
    constructor(props) {
        super(props);
        this.state = {
          resumeData: {}
        };
    }

    getResumeData() {
        $.ajax({
            url: "./resumeData.json",
            dataType: "json",
            cache: false,
            success: function (data) {
                this.setState({ resumeData: data });
            }.bind(this),
            error: function (xhr, status, err) {
                console.log(err);
                alert(err);
            }
        });
    }

    componentDidMount() {
        this.getResumeData();
    }


  render() {

    return (
        <div>
            <Header data={this.state.resumeData.main} />
            <Details data={this.state.resumeData.main} />
            <Resume data={this.state.resumeData.resume} />
            <About data={this.state.resumeData.main} />
            <Portfolio />
            <Footer data={this.state.resumeData.main} />
        </div>
    );
  }
}

export default AICourse;
