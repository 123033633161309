import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Kamil Bugno"
              />
            </div>
            <div className="nine columns main-col">
              <h2>About the author</h2>

              <p>{bio}</p>
              <div className="row">
                <div className="columns main-col">
                  <h2>My cloud-related certifications</h2>
                  <div className="cert-container"> 
                    <img
                      className="cert"
                      src="images/certifications/azure-administrator-associate-600x600.png"
                      alt="Azure Administrator Associate"
                    />
                    <img
                      className="cert"
                      src="images/certifications/AWS-Developer-Associate-2020.png"
                      alt="AWS Developer Associate"
                    />
                    <img
                      className="cert"
                      src="images/certifications/azure-ai-fundamentals-600x600.png"
                      alt="Azure AI Fundamentals"
                    />
                    <img
                      className="cert"
                      src="images/certifications/azure-developer-associate-600x600.png"
                      alt="Azure Developer Associate"
                    />
                    <img
                      className="cert"
                      src="images/certifications/azure-fundamentals-600x600.png"
                      alt="Azure Fundamentals"
                    />
                    <img
                      className="cert"
                      src="images/certifications/azure-security-engineer-associate600x600.png"
                      alt="Azure Security Engineer Associate"
                    />
                    <img
                      className="cert"
                      src="images/certifications/azure-ai-engineer-600x600.png"
                      alt="Azure AI Engineer Associate"
                    />
                  </div>            
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
