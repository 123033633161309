import React, { Component } from "react";
import Header from './lifetime/Header';
import BirthDate from './lifetime/BirthDate';

class Lifetime extends Component {
  render() {

    return (
        <div>
            <Header />
            <BirthDate />
        </div>
    );
  }
}

export default Lifetime;
