import React, { Component } from "react";

class BirthDate extends Component {
    render() {
        return (
            <div className="lifetime">
                <h2>Select your <span style={{ color: "#cb31cd" }}>birthdate</span></h2>
                <div>
                    <input type="number" id="year" name="year"
                        min="1921" max="2020"/>
                </div>
            </div>
        );
    }
}

export default BirthDate;
